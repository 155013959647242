<template>
  <div class="Article">
    <header>{{title}}</header>
    <article v-html="contents">{{contents}}</article>
  </div>
</template>

<script>
import {getArticleDetail} from "@/services/my";

export default {
  name: "Article",
  data() {
    return {
      id:'',
      title: '',
      contents: '',
    };
  },
  methods: {
    getParams() {
      this.id = this.$route.query.id ? this.$route.query.id : '';
      if (this.id) {
        this.getArticleDetail()
      }
    },
    async getArticleDetail() {
      try {
        const res = await getArticleDetail(this.id);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          document.title = data.title;
          this.title = data.title
          this.contents = data.contents
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getParams()
  }

}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.Article {
  background: @color-white;
  height: 100vh;
  padding: 0.54rem 0.24rem;

  header {
    .sc(0.52rem, #333);
    margin-bottom: 0.46rem;
  }

  article {
    .sc(0.3rem, #333);
    line-height: 0.42rem;
  }

}
</style>