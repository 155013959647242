var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Article" }, [
    _c("header", [_vm._v(_vm._s(_vm.title))]),
    _c("article", { domProps: { innerHTML: _vm._s(_vm.contents) } }, [
      _vm._v(_vm._s(_vm.contents)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }